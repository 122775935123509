<template>
  <div class="p-5 sm:p-10 ">

    <div>
      <h1 class="text-2xl sm:text-3xl font-semibold text-gradient-blue inline-block">Getting Started with FOREVER!</h1>
      <p class="text-charcoal-400 text-base sm:text-lg leading-tight">Start your forever journey by learning everything FOREVER has to offer. </p>
    </div>

    <u-divider class="my-4" />

    <div v-if="!currentVideo">
      <!--      <h2 class="font-serif text-2xl">Videos</h2>-->
      <div class="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 gap-5">
        <div v-for="video in videos" :key="video.title" class="rounded flex flex-col cursor-pointer transition-all gap-2 group" role="button" @click="setCurrentVideo(video)">
          <img :src="video.poster" class="rounded shadow aspect-[16/9]" :alt="video.title" />
          <div class="flex gap-2 items-center text-sm">
            <u-icon name="i-ri-play-circle-fill" class="" />
            <span class="text-heroblue font-semibold group-hover:underline">{{video.title}}</span>
            <span class="text-gray-400">{{formatDuration(video.length)}}</span>
          </div>
        </div>
      </div>

      <!--      <h2 class="font-serif text-2xl">Guides</h2>
      <div class="grid grid-cols-4 gap-5">
        <div v-for="guide in guides" :key="guide.title" class="flex gap-2 border border-gray-400 rounded p-3 hover:shadow bg-shell-100 hover:bg-shell-200 cursor-pointer" @click="guide.action" type="button">
          <u-icon :name="guide.icon" class="text-2xl text-charcoal-300" />
          <span class="font-semibold">{{guide.title}}</span>
        </div>
      </div>-->
    </div>

    <template v-else>

      <h2 class="text-2xl font-semibold">{{currentVideo.title}}</h2>
      <div class="flex flex-col gap-2">
        <div class="w-full overflow-hidden relative border-4 rounded border-gray-300">
          <client-only>
            <core-media-player
              :src="currentVideo.url"
              autoplay
              :poster-src="currentVideo.poster"
            />
          </client-only>
        </div>
        <div>
          <u-button @click="setCurrentVideo(null)" icon="i-ri-arrow-left-s-line" variant="link" :padded="false" size="xl">Back</u-button>
        </div>
      </div>
    </template>

    <u-divider class="my-4" />
    <p class="text-right sm:-mb-6 text-sm">
      <span class="font-semibold">Need more help?</span>
      Visit the <u-link :to="EXTERNAL_URLS.helpCenter" :external="true" target="_blank">FOREVER Help Center</u-link>
    </p>
  </div>
</template>

<script setup>
  const currentVideo = ref(null);
  const {openPeopleWelcomeModal} = useCommonModals();

  function setCurrentVideo(video) {
    currentVideo.value = video;
  }

  const videos = [
    {
      title: 'Welcome to FOREVER',
      length: 81_000,
      url: 'https://forever-mars-ui.s3.amazonaws.com/video/f3/hype/hype-f3_welcome.mp4',
      poster: 'https://forever-mars-ui.s3.amazonaws.com/video/f3/hype/hype-f3_welcome.jpg'
    },
    {
      title: 'Facial Recognition',
      length: 50_000,
      url: 'https://forever-mars-ui.s3.amazonaws.com/video/f3/hype/hype-facial_recongition.mp4',
      poster: 'https://forever-mars-ui.s3.amazonaws.com/video/f3/hype/hype-facial_recongition.jpg'
    },
    {
      title: 'How To: Upload Files to FOREVER',
      length: 61_000,
      url: 'https://forever-mars-ui.s3.amazonaws.com/video/f3/tutorial/how_to-uploading.mp4',
      poster: 'https://forever-mars-ui.s3.amazonaws.com/video/f3/tutorial/how_to-uploading.jpg'
    },
    {
      title: 'How To: People',
      length: 93_000,
      url: 'https://forever-mars-ui.s3.amazonaws.com/video/f3/tutorial/how_to-people.mp4',
      poster: 'https://forever-mars-ui.s3.amazonaws.com/video/f3/tutorial/how_to-people.jpg'
    },
    {
      title: 'How To: Groups & Sharing',
      length: 135_000,
      url: 'https://forever-mars-ui.s3.amazonaws.com/video/f3/tutorial/how_to-groups_and_sharing.mp4',
      poster: 'https://forever-mars-ui.s3.amazonaws.com/video/f3/tutorial/how_to-groups_and_sharing.jpg'
    },
    {
      title: 'How To: Filter Your Content',
      length: 169_000,
      url: 'https://forever-mars-ui.s3.amazonaws.com/video/f3/tutorial/how_to-filter_your_content.mp4',
      poster: 'https://forever-mars-ui.s3.amazonaws.com/video/f3/tutorial/how_to-filter_your_content.jpg'
    },
    {
      title: 'How To: Facial Recognition',
      length: 227_000,
      url: 'https://forever-mars-ui.s3.amazonaws.com/video/f3/tutorial/how_to-facial_recongition.mp4',
      poster: 'https://forever-mars-ui.s3.amazonaws.com/video/f3/tutorial/how_to-facial_recongition.jpg'
    }
  ];

  //for later
  const guides = [
    {
      title: 'Welcome to People!',
      icon: COMMON_ICONS.person,
      action: () => openPeopleWelcomeModal()
    }
  ];
</script>
